interface ILiffTypes {
  researchFlight: string;
  researchHotel: string;
  top: string;
}

export const LiffTypes: ILiffTypes = {
  researchFlight: "researchFlight",
  researchHotel: "researchHotel",
  top: "top",
};
