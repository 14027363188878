import { useEffect, useState, useRef } from "react";
import {
  setLIFFInitialized,
  setFacebookMessengerInitialized,
} from "main/javascripts/actions/SocialMediaStatus";
import { createFacebookMessengerAutoSignIn } from "main/javascripts/actions/FacebookMessengerAutoSignIn";
import {
  LINE_LIFF_SDK_URL,
  FACEBOOK_MESSENGER_SDK_URL,
  TOP_LIFF_ID,
} from "main/javascripts/constants/Constants";
import { createLineAutoSignIn } from "main/javascripts/actions/LineAutoSignIn";
import { fetchAccountParams } from "main/javascripts/api/AccountParamApi";
import { initCurrentUser } from "main/javascripts/actions/CurrentUser";
import { CurrentUser } from "main/javascripts/models/CurrentUser";
import { initAccountParams } from "main/javascripts/actions/AccountParams";
import { LiffTypes } from "main/javascripts/constants/LiffTypes";
import { getCookie } from "main/javascripts/utils/cookieUtil";

// LINEから着地するページで使う(useAccountとの違い)
// Login + initをしておく(Loginはcookieで次のページにも引き継がれる)
export const useSocialAutoSignIn: any = (
  store: any,
  liffIdType: string,
  acType: string
): any => {
  const [isLoadedLIFF, setIsLoadedLIFF] = useState(false);
  const [isLoadedFBM, setIsLoadedFBM] = useState(false);
  const [isLoadedAccount, setIsLoadedAccount] = useState(false);
  const [isSocialInitialized, setIsSocialInitialized] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const researchFlightLiffId = useRef("");
  const researchHotelLiffId = useRef("");
  const facebookAppId = useRef("");

  const onLoadScriptLIFF = () => {
    const context = liff?.getOS();
    if (context) {
      setIsLoadedLIFF(true);
    }
  };

  const onLoadScriptFBM = () => {
    setIsLoadedFBM(true);
  };

  const loadScript = (url: string, onload?: any): void => {
    const script: HTMLScriptElement = document.createElement("script");
    script.src = url;
    script.async = true;
    script.onload = onload ? onload : null;
    document.body.appendChild(script);
  };

  // LIFF IDが必要なためここでAccountParamsを取得する
  const loadAccount = async () => {
    const ac_type = acType || getCookie("ac_type") || "line_main";
    const param = `ac_type=${ac_type}`;
    const { accountParams } = await fetchAccountParams(param);
    if (accountParams) {
      const newCurrentUser = accountParams.currentUser;
      setCurrentUser(newCurrentUser);
      store.dispatch(
        initCurrentUser(newCurrentUser ? new CurrentUser(newCurrentUser) : null)
      );
      store.dispatch(
        initAccountParams({
          utmSource: accountParams.utmSource,
          lineRegisterUrl: accountParams.lineRegisterUrl,
          lineType: accountParams.lineType,
          researchFlightLiffId: accountParams.researchFlightLiffId,
          researchHotelLiffId: accountParams.researchHotelLiffId,
          origins: accountParams.origins,
          destinations: accountParams.destinations,
        })
      );
      if (accountParams.lineType) {
        localStorage.setItem("lineType", accountParams.lineType);
      }
      researchFlightLiffId.current = accountParams.researchFlightLiffId;
      researchHotelLiffId.current = accountParams.researchHotelLiffId;
      facebookAppId.current = accountParams.facebookAppId;
      setIsLoadedAccount(true);
    }
  };

  useEffect(() => {
    loadAccount().catch((e) => {
      console.error(e);
    });
  }, []);

  useEffect(() => {
    if (!isLoadedLIFF) {
      if (typeof liff === "undefined") {
        loadScript(LINE_LIFF_SDK_URL, onLoadScriptLIFF);
      } else {
        setIsLoadedLIFF(true);
      }
    } else {
      if (isLoadedAccount) {
        initLiff();
      }
    }
  }, [isLoadedLIFF, isLoadedAccount]);

  useEffect(() => {
    // SDKが読み込まれた後でextAsyncInitを設定しても呼ばれないため
    // SDKがロード済みかどうか判定する必要がある
    if (!isLoadedFBM) {
      if (!(window as any).MessengerExtensions) {
        waitFacebookMessengerScriptLoaded();
        loadScript(FACEBOOK_MESSENGER_SDK_URL);
      } else {
        setIsLoadedFBM(true);
      }
    } else {
      if (isLoadedAccount) {
        initFacebookMessengerExtension();
      }
    }
  }, [isLoadedFBM, isLoadedAccount]);

  const initFacebookMessengerExtension = (): any => {
    console.log("initFacebookMessengerExtension");
    (window as any).MessengerExtensions.getContext(
      facebookAppId.current,
      (result: any): void => {
        // if (!currentUser || !currentUser.toJS().chatPlatformType) {
        store.dispatch(createFacebookMessengerAutoSignIn(result));
        // }
        store.dispatch(setFacebookMessengerInitialized(true));
        setIsSocialInitialized(true);
      },
      (err: any): void => {
        console.log("FBM ERROR ", err);
        // if (options.redirectUrl) {
        //   (window as any).location.href = options.redirectUrl;
        // }
      }
    );
  };

  const waitFacebookMessengerScriptLoaded = (): any => {
    // console.log("waitFacebookMessengerScriptLoaded");
    (window as any).extAsyncInit = (): void => {
      // console.log(" extAsyncInit");
      // FBはscriptのonloadではなくextAsyncInitでload完了とみなす
      onLoadScriptFBM();
    };
  };

  const getLiffId = (): string => {
    if (liffIdType === LiffTypes.researchFlight) {
      return researchFlightLiffId.current;
    } else if (liffIdType === LiffTypes.researchHotel) {
      return researchHotelLiffId.current;
    } else if (liffIdType === LiffTypes.top) {
      return TOP_LIFF_ID;
    }
    return null;
  };

  const initLiff = (): any => {
    const liffId = getLiffId();
    if (!liffId) {
      return;
    }

    // store.dispatch(setIsLIFF(liff.isInClient())); // v2

    if (liff.id && liff.isLoggedIn()) {
      // すでに初期化済み
      store.dispatch(setLIFFInitialized(true));
      setIsSocialInitialized(true);
      return;
    }
    liff
      .init({
        liffId: liffId,
      })
      .then(
        (_: any) => {
          // Now you can call LIFF API
          const accessToken: string = liff.getAccessToken();
          console.log(`accessToken: ${accessToken}`);
          if (accessToken) {
            console.log("AccessToken: ", accessToken);
            // if (!currentUser || !currentUser.toJS().chatPlatformType) {
            console.log("CREATE_LINE_AUTO_SIGN_IN");
            store.dispatch(createLineAutoSignIn({ accessToken: accessToken }));
            // }
            store.dispatch(setLIFFInitialized(true));
            setIsSocialInitialized(true);
          }
        },
        (err: any) => {
          // LIFF initialization failed
          console.log("liff initialization failed", err);
        }
      );
  };

  return [isSocialInitialized, isLoadedAccount, currentUser];
};
