import React from "react";
import { css } from "@emotion/react";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { maxWidth } from "main/javascripts/styles/base/maxWidthStyle";
import { ChatEntryContainer } from "main/javascripts/containers/organisms/ChatEntry/ChatEntryContainer";

export const SearchChatEntry: React.FC = (): React.ReactElement => {
  return (
    <div css={blockStyle}>
      <div css={innerStyle}>
        <ChatEntryContainer />
      </div>
    </div>
  );
};

const blockStyle = css`
  overflow: hidden;
`;
const innerStyle = css`
  padding: ${space.block} ${space.block2x};
  margin: 0 -${space.block2x};
  width: calc(100% + 2rem);
  max-width: ${maxWidth.page1colWide};
  box-sizing: border-box;
  ${moreThanBreakpoint("tablet")} {
    margin: 0 auto;
    width: 100%;
  }
`;
